import "./styles/Footer.css";
import { footerData } from "../data/data";
import { IonIcon, IonButton } from "@ionic/react";
import { HashLink } from "react-router-hash-link";
import { logoFacebook, logoInstagram, logoLinkedin } from "ionicons/icons";

const Footer = () => {
  return (
    <>
      <div className="footer_container">
        <div className="footer_container_main">
          <hr />
          <div className="footer_container_left">
            <h1>Quik Links</h1>
            <ul>
              <li>
                {/* <HashLink to="pastevents/#popover-id" smooth>
                  iZpreneur - GenZ Entrepreneurs Program
                </HashLink> */}
                <a href="pastevents">iZpreneur - GenZ Entrepreneurs Program</a>
              </li>
              <li>
                <HashLink to="/#pastevents" smooth>
                  Past Events
                </HashLink>
              </li>
              <li>
                <HashLink to="/#team" smooth>
                  Team
                </HashLink>
              </li>
              <li>
                <a href="https://courses.idigizen.com/privacy-policy">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/terms-of-Service">Terms of Service</a>
              </li>
            </ul>
          </div>

          <div className="footer_container_middle"></div>

          <hr />
          <div className="footer_container_right">
            <h1>Contact Us </h1>
            <p> E 251A, opposite Paras Hospital, E - Block</p>
            <p>Sushant Lok Phase I, Sector 52A</p>
            <p>Gurugram, Haryana 122002</p>
            <p>{footerData.email}</p>

            <div className="social_links">
              <a href="https://www.facebook.com/idigizenpvtltd/">
                <IonIcon
                  size="large"
                  className="footer-icons"
                  icon={logoFacebook}
                />
              </a>

              <a href="https://www.linkedin.com/company/idigizen">
                <IonIcon
                  size="large"
                  className="footer-icons"
                  icon={logoLinkedin}
                />
              </a>

              <a href="https://www.instagram.com/idigizen/">
                <IonIcon
                  size="large"
                  className="footer-icons"
                  icon={logoInstagram}
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="copyright">{footerData.copyright}</div>
    </>
  );
};

export default Footer;
