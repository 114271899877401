import { Redirect, Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import "./App.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/theme.css";
import Home from "./pages/Home";
import ResponsiveSubscriber from "./components/ResponsiveSubscriber";
import IDigizenSolution from "./pages/IDigizenSolution";
import IZPreneur from "./pages/IZPreneur";
import Payment from "./pages/Payment";
import Stores from "./pages/Store";
import StoreLanding from "./pages/StoreLanding";
import BusinessLandingPage from "./pages/BusinessLandingPage";
import TermsAndConditions from "./components/TermAndCondition";

setupIonicReact();

const App: React.FC = () => (
  <>
    <ResponsiveSubscriber />
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Route exact path="/erp">
            <Home />
          </Route>
          <Route exact path="/terms-of-Service">
            <TermsAndConditions merchantMode={false} />
          </Route>
          <Route exact path="/idigizen-solution">
            <IDigizenSolution />
          </Route>
          <Route exact path="/izpreneur">
            <IZPreneur />
          </Route>
          <Route exact path="/pastevents">
            <Stores />
          </Route>
          <Route exact path="/storeslanding">
            <StoreLanding />
          </Route>
          <Route exact path="/Payment">
            <Payment />
          </Route>
          <Route exact path="/">
            <Redirect to={"idigizen-solution"} />
          </Route>
          <Route exact path="/Business-landing-page">
            <BusinessLandingPage />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  </>
);

export default App;
