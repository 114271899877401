import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import { useAppSelector } from "../app/hooks";
import { getScreenType } from "../data/breakpoints";
import { close } from "ionicons/icons";
import { useHistory } from "react-router";
import { API_SECURE_KEY, IDIGICATOR_URL } from "../data/constants";

const IDigizenPopup: React.FC<{
  openPromo: boolean;
  setOpenPromo: (open: boolean) => void;
}> = (props) => {
  const screenWidth = useAppSelector((state) => state.screenWidth).width;
  const screenType = getScreenType(screenWidth);
  const history = useHistory();

  return (
    <IonModal
      isOpen={props.openPromo}
      onDidDismiss={() => {
        props.setOpenPromo(false);
      }}
      className={
        screenType === "Mobile"
          ? "promo-popover-modal-mob"
          : "promo-popover-modal"
      }
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>July 8 - July 29!</IonTitle>
          <IonButtons slot="end">
            <IonButton
              color={"danger"}
              fill="clear"
              size="small"
              onClick={props.setOpenPromo.bind(this, false)}
            >
              <IonIcon icon={close} />
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div style={{ height: "100%", width: "100%" }}>
          <img
            src="assets/images/promo.png"
            alt="Join our summer programme to keep the bussiness booming"
            style={{ height: "100%", width: "100%", objectFit: "scale-down" }}
          />
        </div>
        d
      </IonContent>
      <IonFooter>
        <div
          style={{
            display: "flex",
            flexDirection: screenType === "Mobile" ? "column-reverse" : "row",
          }}
        >
          <div style={{ width: screenType === "Mobile" ? "100%" : "50%" }}>
            <IonButton
              color={"tertiary"}
              expand="block"
              fill="solid"
              onClick={() => {
                props.setOpenPromo(false);
                history.push("/izpreneur");
              }}
            >
              Know More
            </IonButton>
          </div>
          <div style={{ width: screenType === "Mobile" ? "100%" : "50%" }}>
            <IonButton
              color={"secondary"}
              expand="block"
              fill="solid"
              onClick={() => {
                window.location.assign(
                  `${IDIGICATOR_URL}forms?type=Form&id=205&Authorization=${API_SECURE_KEY}&submitOnly=true&logoName=iDigizen_Logo.png`
                );
              }}
            >
              Interested!
            </IonButton>
          </div>
        </div>
      </IonFooter>
    </IonModal>
  );
};

export default IDigizenPopup;
