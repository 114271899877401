import "./styles/collage.css";
import { IonButton } from "@ionic/react";
import { HashLink } from "react-router-hash-link";
export const Collage = (props) => {
  return (
    <>
      <div className="collage-container">
        <div className="collage">
          <img src="/assets/images/1.png" className="collage-image1" alt=" " />
          <img src="/assets/images/2.png" className="collage-image2" alt=" " />
          <img src="/assets/images/4.png" className="collage-image3" alt=" " />
        </div>
      </div>
    </>
  );
};

export const CollageText = (props) => {
  const btn = props.btnStyle;
  return (
    <div className="callage_text">
      <p>
        Students of class 6-12 showcase the journey of launching their first
        venture. zSharks is culmination of iZPreneur program where each
        participant creates their own enterprise, forges business partnership
        and executes on their venture. The program harnesses the talents of each
        student enabling them to transform their interests and passions into
        viable enterprises.{" "}
        <span className="blue_text"> zPreneurs, our GenZ entrepreneurs</span>{" "}
        have showcased exceptional innovation and vibrance brought out by
        channelizing their passions into multiple unique ventures.
      </p>
      <p>
        {" "}
        <span className="blue_text">
          {" "}
          RhymeIt: a novel Rhyme & Bake venture by Kashika Gupta,
        </span>{" "}
        a venture that is offering custom poems and bakes for your loved ones
        and special occasions.
      </p>

      <p>
        <span className="blue_text">Scotch-Tales: another unique venture,</span>{" "}
        enabling you to indulge your pet-children and express your love for
        them. Order custom-cakes and bakes from Scotch-Tales. The tag-line “Each
        Tail has a tale to tell”, expresses the love and care that goes into
        these custom bakes.
      </p>

      <p>Click below explore all stores created by our zPreneurs! </p>

      <div
        style={{
          height: "70px",
          display: btn,
          justifyContent: "center",
        }}
      >
        <IonButton shape="round" color={"secondary"} className="listing_btn">
          <HashLink to="pastevents" smooth>
            Explore Stores{" "}
          </HashLink>
        </IonButton>
      </div>
    </div>
  );
};
