import React from "react";

import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonPage,
} from "@ionic/react";
import { checkmark, close } from "ionicons/icons";

const TermsAndConditions: React.FC<{
  merchantMode: boolean;
}> = (props) => {
  const merchantModeTermsJsx = (
    <>
      <ol>
        <li>
          <b>USAGE POLICY</b>
          <br />
          <ol>
            <li>
              You will use the iDigizen Software in a manner consistent with all
              local, states and Union regulations and laws. We reserve the right
              to suspend or cancel your access to any or all services provided
              by us when you are not in compliance to the terms of this
              agreement and/or we decide that your account has been
              inappropriately used
            </li>
            <li>
              By agreeing to this policy you are giving consent to Vypzee
              platform to host your business and all the business information as
              reviewed by you
            </li>
          </ol>
        </li>
        <li>
          <b>TERMS OF USE</b>
          <br />
          This agreement only gives you rights to use the software as per the
          features obtained and fee paid by you. You may use the software only
          as expressly permitted in this agreement. In doing so, you must comply
          with any technical limitations in the software that only allow you to
          use it in certain ways. Clients are hereby granted, non-exclusive,
          right and license to use the software program or the rights as granted
          under this agreement
        </li>
        <li>
          <b>CLIENT’S RESPONSIBILITIES</b>
          <br />
          Effective use of software provided by us presumes a certain degree of
          knowledge and skill on the part of the Client. We will not be held
          responsible for your inability to use our software due to your lack of
          the requisite knowledge and skills
        </li>
        <li>
          <b>FEES</b>
          <br />
          You agrees to pay a non-refundable Service Fee for the Services that
          will be determined by iDigizen at the time of accepting the Service.
          The ("Service Fee") shall be paid by You in advance and IDigizen will
          send the customer receipt/Invoice to Your registered Mobile Number and
          email ID, simultaneously after receipt of payment from You. IDigizen
          shall, at its sole discretion, reserve its right to decide the Service
          Fee for the Services, from time to time.
        </li>
        <li>
          <b>COMPATIBILITY</b>
          <br />
          This software is available on website as well as on mobile app and in
          this software you have the option to host your data anywhere in the
          world. However your devices or computer resources should be compatible
          with the iDigizen software. If your devices or computer resources does
          not sync with the iDigizen software, and causes any error or any loss
          or damage resulting from non-performance software, IDigizen shall not
          be liable for any reason whatsoever
        </li>
        <li>
          <b>SERVICE SUPPORT</b>
          <br />
          IDigizen will use commercially reasonable efforts to make available
          IDigizen Support Center on Monday through Friday, during IDigizen's
          normal business hours, excluding IDigizen's scheduled holidays.
          IDigizen will provide you all the customer and technical support via
          telephone hotline support or through info@idigizen.com. However our
          support services shall be limited to providing matter pertaining our
          software under this agreement not otherwise.
        </li>
        <li>
          <b>LICENSE FEES CHANGE</b>
          <br />
          IDigizen reserves the right to increase/raise the license fees of
          Software, at its discretion. The license fees will only become
          effective upon renewal of the order. For clarity, additional purchases
          of the software/ other features will be subject to the new pricing
        </li>
        <li>
          <b>CLIENT’S OBLIGATION</b>
          <br />
          You represent and warrant that
          <ol>
            <li>
              You would be solely responsible and liable for the information
              uploaded, edited, modified in the Software provided by IDigizen
            </li>
            <li>
              You shall be solely responsible and liable for any dispute arising
              out of your usage of our services
            </li>
            <li>
              You acknowledge and confirm that you will be subject to the rules,
              guidelines, policies, terms, and conditions applicable to the
              Vypzee application. IDigizen reserve the right, at its discretion,
              to change, modify, add or remove portions of these Terms, at any
              time without any prior written notice to you
            </li>
            <li>
              You shall ensure and take all appropriate security measures to
              prevent unauthorized disclosure and/or access of any details such
              as user ids, passwords, sensitive personal information or any
              other information of any end-customers/ Customers, third party
              which are obtained by you in any manner whatsoever
            </li>
          </ol>
        </li>
        <li>
          <b>DISCLAIMER OF WARRANTY</b>
          <br />
          This software is available to you “as-is whereas basis." You bear the
          risk of using it. IDigizen gives no express warranties, guarantees or
          conditions. IDigizen will make all reasonable efforts to provide
          uninterrupted access subject to down time and regular maintenance
        </li>
        <li>
          <b>CONTENT OWNERSHIP</b>
          <br />
          <ol>
            <li>
              All contents stored by you shall at all times remain the property
              of yours. You grant to IDigizen nonexclusive, world-wide license
              to the content for the purposes of analytics and reporting or any
              other related purposes
            </li>
            <li>
              IDigizen uses the information it collects through the Vypzee
              Application to upgrade or secure its Services. Additionally, you
              agree that IDigizen may use and disclose the information and you
              would be deemed to have given your consent to contact you for any
              business promotion of IDigizen and its affiliates
            </li>
          </ol>
        </li>
      </ol>
    </>
  );
  const userModeTermsJsx = (
    <>
      <p>
        This is an agreement between you ("you", " your" "caller" or "user") and
        iDigizen Pvt Limited. ("iDigizen", “Vypzee”, "we," or "our") that
        governs your use of the application offered by iDigizen under the brand
        name of Vypzee. When you access, use or book any orders using any of the
        Platforms you agree to be bound by these Terms and Conditions
      </p>
      <ol>
        <li>
          “Agreement” is for the purposes of for exploring information or
          placing an enquiry or order of goods through merchants
          registered/listed within the Vypzee Application as described in this
          agreement.
        </li>
        <li>
          <b>Content disclaimer: </b>
          <br />
          Vypzee platform has the following listings
          <ol>
            <li>
              <b>Markets: </b>Vypzee platform designates any space with a
              collection of merchants with stores as a market. This maybe an
              entity which is recognized by law or can be a physical space with
              a name coined by vypzee. The application will have 2 types of
              markets:
              <ol>
                <li>
                  <b>Partnered Market:</b>Partnered market information are
                  curated with help from the market’s RWA or any other governing
                  body registered to represent the market by law.
                </li>
                <li>
                  <b>“Vypzee Curated” Market: </b>Vypzee curated market
                  information are curated by Vypzee’s market survey. This market
                  has no connection with the RWA or any other governing body
                  registered to represent the market (if present). Such markets
                  are explicitly labelled “Vypzee Curated”
                </li>
              </ol>
            </li>
            <li>
              <b>Merchant / Business: </b>merchant/business within the market
              designated will have information on the merchant, what they deal
              in, contact info and other business information. There are 2 types
              of merchants listed in the application
              <ol>
                <li>
                  <b>Partnered Merchants: </b>Merchant shops where all
                  information about the store and products are owned by the
                  merchant
                </li>
                <li>
                  <b>“Vypzee Curated” Merchant: </b>A Vypzee scouted merchant’s
                  information is curated by Vypzee’s market survey. Such
                  merchants are explicitly labelled “Vypzee Curated”
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <b>Order & Enquiry: </b>
          <br />
          <ol>
            <li>
              All the orders/enquiries placed by you in Vypzee’s application
              will be forwarded by iDigizen to the respective merchant where the
              order was places
            </li>
            <li>
              Fulfilment of Orders or enquires are the sole responsibility of
              the merchant listed on Vypzee. Vypzee records and forwards the
              query/order to the concerned merchant. Vypzee or any of its
              employees or contractors will not be held liable for the response
              to or fulfilment of the query for order placed with Vypzee’s
              application respectively.
            </li>
            <li>
              For completed orders placed via the Vypzee platform, Vypzee is not
              liable for ensuring quality of products delivered to you. The
              responsibility of the order fulfilment lies solely with the
              business ordered from.
            </li>
          </ol>
        </li>
        <li>
          <b>
            Errors, Corrections, and Right to Modify or Discontinue Service and
            Site:
          </b>
          <br />
          <ol>
            <li>
              We derive our information from sources which we believe to be
              accurate and up to date at the time of publication. All
              information provided on the Vypzee Application is provided in good
              faith. We do not make any representation or warranty that the
              information provided is timely, reliable, accurate or complete;
              and will not be liable for any claim or loss resulting from any
              action taken, or reliance made by you on any information, material
              or advice provided on the application, or for any incorrect
              information provided on the application.
            </li>
            <li>
              We do not represent or warrant that the Vypzee application will be
              error-free, free of viruses or other harmful components, or that
              defects will be corrected.
            </li>
            <li>
              We reserve the right in our sole discretion to edit or delete any
              documents, information or other content appearing on the
              application.
            </li>
            <li>
              iDigizen reserves the right at any time and from time to time to
              modify or discontinue, temporarily or permanently, the Services
              and/or Sites (or any part thereof) with or without notice.
              iDigizen shall not be liable to you or to any third party for any
              modification, suspension or discontinuance of the Service or any
              Sites
            </li>
          </ol>
        </li>
        <li>
          <b>Availability: </b>
          <br />
          <ol>
            <li>
              iDigizen will use reasonable efforts to make the Vypzee
              application available at all times. However you acknowledge the
              Vypzee Application is provided over the internet and mobile
              networks and so the quality and availability of the application
              may be affected by factors outside iDigizen's reasonable control.
            </li>
            <li>
              iDigizen does not accept any responsibility whatsoever for
              unavailability of the Application, or any difficulty or inability
              to download or access content or any other communication system
              failure which may result in the Application being unavailable
            </li>
          </ol>
        </li>
      </ol>
    </>
  );

  return (
    <IonPage>
      <IonHeader>
        <div
          style={{
            paddingTop: "20px",
            textAlign: "center",
            width: "100%",
            fontSize: "1rem",
            fontWeight: "bold",
            color: "black",
          }}
        >
          Terms And Conditions
          {
            <div style={{ padding: "5px", display: "flex" }}>
              <div style={{ width: "50%" }}>
                <IonButton expand="block" size="small" onClick={() => {}}>
                  {" "}
                  <IonIcon icon={checkmark} slot="start" />I Accept
                </IonButton>
              </div>
              <div style={{ width: "50%" }}>
                <IonButton
                  expand="block"
                  fill="clear"
                  color={"danger"}
                  size="small"
                  onClick={() => {}}
                >
                  {" "}
                  <IonIcon icon={close} slot="start" />I Do Not Accept
                </IonButton>
              </div>
            </div>
          }
        </div>
      </IonHeader>
      {/* <BhpThemeHeaderWithBack
        title="Terms & Conditions"
        onBack={props.onDidDismiss}
      /> */}
      <IonContent>
        <div style={{ padding: "5px 10px", fontSize: ".8rem" }}>
          <p>
            This document is an electronic record in terms of the amended
            Information Technology Act, 2000 and rules and regulation made
            thereunder. This electronic record is generated by a computer system
            and does not require any physical or digital signatures. This
            document is published in accordance with the provisions of Rule 3
            (1) of the Information Technology (Intermediaries Guidelines) Rules,
            2011 that require publishing the Terms for access or usage of
            iDigizen’s Vypzee Platform.
          </p>
          <p>
            This is an agreement between iDigizen Pvt Ltd., a company
            incorporated under the Companies Act 1956 with its registered office
            at E-251-A, Shushant Lok Phase-1, Sector 51-A, Gurugram, Haryana
            122002, the owner and operator of{" "}
            <a href="https://vypzee.com">vypzee.com</a> and all the pages and
            sub-domains under the domain name of vypzee, and you ("you" or
            "You"), a user of the Service. BY USING THE SERVICE, YOU ACKNOWLEDGE
            AND AGREE TO THE FOLLOWING TERMS OF SERVICE.
          </p>
          <h3>Terms Of Use</h3>
          {!props.merchantMode && userModeTermsJsx}
          {!!props.merchantMode && merchantModeTermsJsx}
          <h3>Privacy Policy</h3>
          <ol>
            <li>
              <b>Introduction</b>
              <br />
              <ol>
                <li>
                  We respect your privacy rights and recognize the importance of
                  secure transactions and information privacy. This Privacy
                  Policy describes how iDigizen collect, use, share or otherwise
                  process your personal information through Vypzee application.
                </li>
                <li>
                  By visiting Vypzee, providing your information or availing our
                  product/service, you expressly agree to be bound by the terms
                  of this Privacy Policy and the applicable Terms of service.
                  While accessing or using our platform/services, you have given
                  explicit consent to collect, use, share or otherwise process
                  your information in accordance with this Privacy Policy. If
                  you do not agree, please do not access or use our Platform or
                  service.
                </li>
                <li>
                  You acknowledge that you are disclosing Personal Information
                  voluntarily. Prior to the completion of any registration
                  process on our platform or prior to availing of any services
                  offered on our platform if you wish not to disclose any
                  Personal Information you may refrain from doing so; however if
                  you don't provide information that is requested, it is
                  possible that the registration process would be incomplete
                  and/or you would not be able to avail of the our services. If
                  you are our corporate customer, it is possible that we have
                  entered into a contract with you for non-disclosure of
                  confidential information. This Policy shall not affect such a
                  contract in any manner.
                </li>
              </ol>
            </li>
            <li>
              <b>Application of policy</b>
              <br />
              This policy applies to all contractors, suppliers, customers,
              users, vendors, sellers, partners, and other third party working
              on behalf of iDigizen or accessing or using our platforms/service.
            </li>
            <li>
              <b>Objectives of policy</b>
              <br />
              iDigizen is required to collect & use certain information about
              individuals, i.e., customers, suppliers/vendors, business
              contacts, employees, and the third parties with whom iDigizem has
              a business relationship or may need to contact. This policy
              describes, how iDigizen collects, receives, possesses, stores,
              deals or handle personal information including sensitive personal
              information about you and ensure that the same are available for
              review by you. The personal information must be collected, handled
              and stored to meet the data protection standards as well as
              legislative requirements. This policy ensures to comply with data
              protection law and follows the good practice and protects the
              rights of employees, customers, suppliers/vendors, business
              contacts, employees, and the third parties and how to stores and
              processes data and protects from the risks of data breach.
            </li>
            <li>
              <b>Collection of information</b>
              <br />
              <ol>
                <li>
                  In the course of carrying out its various functions and
                  activities, iDigizen collects information from individuals &
                  third parties and generates a wide range of information which
                  is stored at our platform.
                </li>
                <li>
                  We collect Personal Information from you in a variety of ways
                  when you interact with us through our services, such as,
                  installing, accessing, or using our Services; Create an
                  account on our services or interact with merchants using
                  iDigizen’s application.
                </li>
                <li>
                  In the course of registering for and availing various services
                  we provide from time to time through Vypzee application in
                  which iDigizen may provide you may be required to give your
                  name, residence address, email address, phone number, and
                  similar Personal Information ("Personal Information").
                </li>
              </ol>
            </li>
            <li>
              <b>Usage of Information</b>
              <br />
              <ol>
                <li>To provide services.</li>
                <li>To help us personalize our service offerings</li>
                <li>For operation and maintenance purposes</li>
                <li>
                  It helps us in operating, providing, improving, understanding,
                  customizing, supporting, and marketing our Services
                </li>
                <li>
                  Personal Information will be kept confidential and will be
                  used for our research, marketing, and strategic client
                  analysis objectives and other internal business purposes only.
                  We do not sell Personal Information.
                </li>
                <li>
                  Personal information while transacting with specific merchants
                  on the Vypzee platform for loyalty, coupon download and other
                  such services will be shared with the specific merchants for
                  which you transacted for.
                </li>
                <li>
                  We do not use your mobile phone number or other Personally
                  Identifiable Information to send commercial or marketing
                  messages without your consent or except as part of a specific
                  program or feature for which you will have the ability to
                  opt-in or opt-out. We may use cookies and log file information
                  to (a) remember information so that you will not have to
                  re-enter it during your re-visit on our platform; (b) provide
                  custom, personalized content and information; (c) monitoring
                  or pages viewed, etc.; and (d) track your entries,
                  submissions, views etc.,.
                </li>
              </ol>
            </li>
            <li>
              <b>Protection of Personal Information:</b>
              <br />
              <ol>
                <li>
                  We are committed to protecting the privacy of your personal
                  information. While we do not sell, share, or lease your
                  personal information to third parties for marketing or
                  promotional purposes without your explicit consent, please
                  note that if you voluntarily provide your personal information
                  to a specific third-party business through our platform, you
                  may be subject to their privacy policies and practices.
                </li>
                <li>
                  By engaging with a third-party business on our platform and
                  providing them with your personal information, you consent to
                  their use of your information for marketing and promotional
                  purposes in accordance with their privacy policies.
                </li>
                <li>
                  iDigizen cannot guarantee or control the use of your personal
                  information by third-party businesses. If you have any
                  concerns about the use of your personal information by a
                  third-party business, please contact them directly to address
                  your concerns. iDigizen cannot be held liable for how the 3rd
                  party uses your personal information.
                </li>
              </ol>
            </li>
            <li>
              <b>Grievance Officer</b>
              <br />
              In accordance with Information Technology Act 2000 and rules &
              regulations made thereunder, the name and contact details of the
              Grievance Officer are provided below:
              <br />
              <br />
              Mr. Jishnu De
              <br />
              Address: E-251-A, Shushant Lok Phase 1, Sector 51-A, Gurugram,
              Haryana
              <br />
              Email: info@idigizen.com
              <br />
              Contact: 8448338565
            </li>
          </ol>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TermsAndConditions;
