import {
  IonHeader,
  IonButton,
  IonGrid,
  IonRow,
  IonPage,
  IonContent,
} from "@ionic/react";
import FeatureCardModern from "../components/FeatureCardModern";
import LandingStoreBanner from "../components/LandingStoreBanner";
import Header from "../components/Header";
import { useHistory } from "react-router";
import "./styles/StoreLanding.css";
import GraphicWithText from "../components/GraphicWithText";
import LandingPageListing from "../components/LandingPageListing";
import Footer from "../components/Footer";
import {
  API_SECURE_KEY,
  IDIGICATOR_URL,
  SCHOOLREG_FORM_ID,
} from "../data/constants";
import { LandingStoreBannerData } from "../data/LandingPageData";
import { FeaturesModernCardData } from "../data/LandingPageData";
import { features } from "process";
import "../components/styles/FeatureCardModern.css";

const StoreLanding = () => {
  const history = useHistory();
  const goToRegistrationForm = () => {
    window.location.assign(`https://idigicator.idigizen.com/home`);
  };

  const BannerCallToActionButtonJSX = (
    <>
      <IonButton
        shape="round"
        color={"secondary"}
        fill="solid"
        onClick={() => {
          window.location.assign("https://idigicator.idigizen.com/home");
        }}
      >
        <div style={{ color: "white" }}>Open Your Store</div>
      </IonButton>
    </>
  );

  return (
    <IonPage>
      <IonContent>
        <Header
          navref={undefined}
          actionButtonName="Register Now!"
          onActionButtonClick={goToRegistrationForm}
          buttons={[
            {
              name: "Home",
              onClick: () => {
                history.push("/");
              },
            },
            {
              name: "School",
              onClick: () => {
                history.push("/erp");
              },
            },
            {
              name: "Stores",
              onClick: () => {
                history.push("/pastevents");
              },
            },
            {
              name: "IZPreneur",
              onClick: () => {
                history.push("/izpreneur");
              },
            },
          ]}
        />
        <LandingStoreBanner
          headerSubtitle={LandingStoreBannerData.header.subtitle}
          headerTitle={LandingStoreBannerData.header.titie}
          headerButton={BannerCallToActionButtonJSX}
          MobImage={LandingStoreBannerData.image.imageMob}
          WebImage={LandingStoreBannerData.image.imageWeb}
        />
        <LandingPageListing />

        <div
          className="feature_card_container"
          // style={{ display: "flex", flexDirection: "column" }}
        >
          <IonGrid>
            <IonRow className="feature_card_row">
              {FeaturesModernCardData.body.map((item) => {
                return (
                  <>
                    <FeatureCardModern
                      title={item.title}
                      img={item.imgPath}
                      text={item.text}
                    />
                  </>
                );
              })}
            </IonRow>
          </IonGrid>
        </div>

        <GraphicWithText />
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default StoreLanding;
